import { createWebHistory, createRouter } from "vue-router";
import Hero from "./components/V1/view/Hero.vue";
import Login from "./components/V1/view/Login.vue";
// lazy-loaded
const Dashboard = () => import("./components/V1/view/Dashboard.vue")
const Profile = () => import("./components/V1/view/Profile.vue")
const Users = () => import("./components/V1/view/Users.vue")
const Moderator = () =>  import('./components/V1/view/Moderator.vue')

const Register =() => import ("./components/Register.vue")


function HasAccess(to, from, next, allowedRoles){
  const currentUser = JSON.parse(localStorage.getItem('user'));
  var isAllowed = false;

  console.log(`Check rights allowed: ${allowedRoles[0]} in user rights ${currentUser.roles}`);

  for (let i=0;i<allowedRoles.length;i++){
    let MyRole = allowedRoles[i];
    console.log(`check role: ${MyRole}`);
    if (currentUser.roles.includes(MyRole)) {
      isAllowed = true;
      break;
    }
  }

  if (isAllowed) {
    console.log('acces granted');
    next();
  } 
  else {
    console.log('acces denied');
    next(from.fullPath);
  }
}




const routes = [
  {
    path: "/",
    name: "home",
    component: Hero,
  },
  {
    path: "/login",
    name: 'login',
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    //lazy-loaded
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/users",
    name: "users",
    // lazy-loaded
    component: Users,
    beforeEnter: (to, from, next) => {HasAccess(to, from, next,['ROLE_ADMIN'])}
  },
  {
    path: "/moderator",
    name: "moderator",
    // lazy-loaded
    component: Moderator,
    beforeEnter: (to, from, next) => {HasAccess(to, from, next,['ROLE_ADMIN','ROLE_MODERATOR'])}
  },
  {
    path: "/dashboard",
    name: "dashboard",
    // lazy-loaded
    component: Dashboard,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/home', '/'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
  
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/');
    } else {
      next();
    }
  });

export default router;