import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import {
//   faHome,
//   faUser,
//   faUserPlus,
//   faSignInAlt,
//   faSignOutAlt,
// } from "@fortawesome/free-solid-svg-icons";

// library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);



import {fas} from "@fortawesome/free-solid-svg-icons"

library.add(fas);

export { FontAwesomeIcon };