<template>
  <div id="app">
     <div class="background">
        <div class="content-background">
            <div class="content">
                <router-view />
            </div>
            <footer>
                Agriscale &copy; 2012
            </footer>
        </div>
     </div>
  </div>
</template>

<script>

</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap');

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    border-style: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: whitesmoke;
}


html {
    scroll-behavior: smooth;
}


.background{
    /* outline:red solid 1px; */
    height: 100vh;
    min-height:600px;
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(./assets/bg.jpg);
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -999;
}


.content-background{
    /* outline:red solid 1px; */
    position: absolute;
    height: 100vh;
    min-height:600px;
    width: 100%;
    background-image: linear-gradient(-60deg, rgba(14, 245, 45, 0.1), rgba(17, 67, 231, 0.5), rgba(7, 61, 241, 0.8));
    background-size: 200% 200%;
    background-position: center;
    animation: gradient 15s ease infinite;
    z-index: -2;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.content{
    /* outline:yelow solid 1px; */
    position:absolute;
    top:0;
    left:0;
    width:100%;
}

.panel{
    /* outline:green solid 1px; */
    position:relative;
    top:6em;
    width:90%;
    height:80vh;
    background:linear-gradient(rgba(30,30,30,0.95), rgba(40,40,40,0.9));
    margin: 0 auto;
    border-radius:1em;
    padding: 2em 3em 4em 5em;
}

footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 2em;
    background-color: rgba(2, 12, 146, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}


</style>
