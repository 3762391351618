<template>
    <div class="navigation">
        <nav>
            <div  v-if="currentUser" class="brand">
              <h1>Agri<span>scale</span></h1>
            </div>
            <ul>
                <li onmouseover="" v-if="currentUser"><font-awesome-icon class="icon" icon="user" />
                    {{ currentUser.username }}
                    <ul >
                        <li v-if="currentUser"><router-link to="/dashboard"><font-awesome-icon class="icon" icon="tachometer" />Dashboard</router-link></li>
                        <li v-if="currentUser"><router-link to="/profile"><font-awesome-icon class="icon" icon="user" />Profile</router-link></li>
                        <div v-if="IsModerator||IsAdmin" class="separator"></div>
                        <li v-if="IsAdmin"><router-link to="/users"><font-awesome-icon class="icon" icon="users" />User Management</router-link></li>
                        <li v-if="IsModerator||IsAdmin"><router-link to="/moderator"><font-awesome-icon class="icon" icon="pencil" />Moderator Board</router-link></li>
                        <div class="separator"></div>
                        <li v-if="currentUser"><a @click.prevent="logOut"><font-awesome-icon class="icon" icon="sign-out-alt" />LogOut</a></li>
                    </ul>
                </li>

                

                <li ><router-link v-if="!currentUser" to="/login" ><font-awesome-icon class="icon" icon="sign-in-alt" />Login</router-link></li>
            </ul>
        </nav>
    </div>
</template>


<script>
export default {
    name: "Navigation",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    IsAdmin() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }
      return false;
    },
    IsModerator() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }
      return false;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    }
  }
};
</script>


<style scoped>

/******************************************************************************* */

.navigation{
    /* outline:red solid 1px; */
    position:absolute;
    width:100%;
    padding-top:1em;
    padding-right:5em;
    z-index:1000;
}

nav{
    /* outline:green solid 1px; */
  margin-left:5em;
  display:flex;
}

nav h1 {
    /* outline:red solid 1px; */
    position: relative;
    font-size: 2.5em;
}

h1 span {
    -webkit-text-stroke: 1px whitesmoke;
    color: transparent;
    position: relative;
    font-size: 1em;
}



ul {
    width:100%;
    margin: auto;
    display: flex;
    justify-content:right;
    align-items: center;
}

ul li {
    list-style:none;
    padding: 0px 5px;
}

ul li::after{
    content: '';
    width:0;
    height: 2px;
    background: linear-gradient(to right, #ffef10, #ffad06);
    display: block;
    margin: auto;
    transition: 0.5s;   
}

ul li:hover::after{
    width: 100%;
}

ul li a{
    text-decoration:none;
}

/* submenu */
ul ul{
    position:absolute;
    background:linear-gradient(rgba(182, 182, 182, 0.5),rgba(65, 64, 64, 0.8));
    width:17em;
    padding:1em;
    z-index: 1000;
    border-radius: 1em 0 1em 1em;
    right:90px;

}

ul ul li{
    padding-top:0.5em;
}

/* Dropdown triggers */
nav ul ul, nav ul li:hover ul ul, nav ul ul li:hover ul ul {display: none;}
nav ul li:hover ul, nav ul ul li:hover ul, nav ul ul ul li:hover ul {display: block;}

.separator{
    margin-top:1.2em;
    border-bottom:#ffad06 solid 1px;
    margin-bottom:0.4em;
}

.icon{
    padding-right: 5px;
}


/******************************************************************************* */

@media only screen and (max-width: 720px) {
  .navigation{
    padding-right:0em;
  }
  ul{
    width:100%;
  }
  ul ul{
    border-radius: 1em 0 1em 1em;
    right:15px;
  }
  ul li {
    padding: 0px 5px;
  }


}



</style>