<template>
    <div>
        <div class="form-box">
            <div v-if="message" class="message">
                <font-awesome-icon class="icon" icon="triangle-exclamation" />{{ message }}
            </div>

            <form class="input-group" @submit.prevent="handleLogin(this.user)">
                <input type="text" class="input-field" placeholder="User Id" v-model="user.username" required>
                <input type="password" class="input-field" placeholder="Enter Password" v-model="user.password" required>
                <input type="checkbox" class="check-box"><span>Remember Password</span>
                <button type="submit" class="submit-btn">Log in</button>
            </form>

        </div>
    </div>
</template>



<script>

export default {
  name: "Login",
  components: {
  },
  data(){
    return{
        user:{
            username:"",
            password:""
        },
        message:""
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    handleLogin(user) {
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/dashboard");
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      ); 
    },
  },
};
</script>


<style scoped>

.form-box {
    position: relative;
    height: 480px;
    width: 380px;
    margin: 5% auto;
    background: linear-gradient(130deg, rgba(220,220,220,0.5), rgba(220,220,220,0.9));
    padding: 5px;
    border-radius: 10px;
    border: 5px;
}

@media only screen and (max-width: 600px) {
    .form-box {
        max-width: 95%;
    }
}


.input-group {
    position: absolute;
    top: 180px;
    width : 280px;
    left:50px;
}

.input-field {
    width: 100%;
    padding: 10px 0; 
    margin:2px;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #ddd;
    outline: none;
    background: transparent;
}

.input-field::placeholder{
    color: #eee;
    font-size: 0.9rem;
    font-weight: 200;
}

input[type=checkbox] {
    accent-color: #ffad06;
}
 
.check-box {
    margin: 30px 10px 30px 0;
}

#checkbox-span {
    font-size: 0.9rem;
}

.submit-btn {
    width: 85%;
    display: block;
    margin: auto;
    padding: 10px 30px;
    cursor: pointer;
    background: linear-gradient(to right, #ffef10, #ffad06);
    outline: none;
    border-radius: 30px;
    border: 0;
}

#login {
    left: 50px;
}

.message {
  color:red;
  font-size: 1em;
  position:absolute;
  top:150px;
  left: 50px;
  width:85%;
  margin:auto;
}

.icon{
  font-size:2em;
  padding-right:1em;
  color:red;
}
</style>