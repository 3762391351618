import axios from 'axios';
import { API_PATH } from './api';

const API_URL = API_PATH + 'auth/';

class AuthService {
  async login(user) {
    return axios.post(API_URL + 'signin', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  async register(user) {
    console.log(`auth.service.register ${API_URL + 'signup'}`)
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();