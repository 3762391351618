<template>
    <div>
        <Navigation />
        <div class="hero">
            <h1>Agri<span>scale</span></h1>
            <p>Handgemaakte miniaturen, niet van echt te onderscheiden!</p>
        </div>
    </div>
</template>

<script>
 import Navigation from '@/components/V1/Navigation.vue'

export default ({
    name: "Hero",
    components: { 
        Navigation 
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    created(){
        if(this.currentUser) this.$router.push('/dashboard');

    }
})
</script>

<style scoped>
.hero{
    /* outline: red solid 1px; */
    position:relative;
    padding-top:3em;
    padding-left: 10em;
}

.hero h1 {
    position: relative;
    font-size: 8em;
}

.hero p {
    position: relative;
    font-size: 1.3em;
}

h1 span {
    -webkit-text-stroke: 1px whitesmoke;
    color: transparent;
    position: relative;
    font-size: 1em;
}


@media only screen and (max-width: 1450px){
    .hero {
        padding-left: 5em;
    }
    .hero h1 {
        font-size: 4.5em;
    }
    .hero p {
        font-size: 0.72em;
    }
            
}

@media only screen and (max-width: 780px){
    .hero {
        padding-left: 3em;
    }
    .hero h1 {
        font-size: 3.5em;
    }
    .hero p {
        width: 270px;
        font-size: 0.9em;
    }
            
} 
</style>